import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from '../api/api.js';
import { Helmet } from 'react-helmet-async';
import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  Paper,
} from "@mui/material";

const Register = () => {
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [err, setError] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.post("/api/auth/register", inputs);
      navigate("/login");
    } catch (err) {
      setError(err.response.data);
    }
  };


  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="h3"
        className="iranSans"
        sx={{ maxWidth: 480, textAlign: "center" }}>
        خوش آمدید
      </Typography>

      <Box
        component="img"
        alt="auth"
        src="../assets/illustrations/illustration_dashboard.png"
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
        }}
      />
    </Stack>
  );

  const renderContent = (
    <Paper>

      <Stack
        spacing={2}
        sx={{
          width: 1,
          mx: "auto",
          maxWidth: 480,
          px: { xs: 2, md: 8 },
          pt: { xs: 15, md: 20 },
          pb: { xs: 15, md: 0 },
        }}
      >
        <Typography
          alignSelf="center"
          className="iranSans"
          variant="h2">ثبت نام</Typography>

        <form>
          <Stack spacing={2}>
            <TextField
              label="نام کاربری" // Label displays above the input field
              id="username" // ID helps associating form data with state
              name="username"
              size="small" // Change size if required
              onChange={handleChange} // Pass handleChange function as event handler
              color="secondary"
              dir="ltr"
            />
            <TextField
              label="آدرس ایمیل" // Label displays above the input field
              id="email" // ID helps associating form data with state
              name="email"
              size="small" // Change size if required
              onChange={handleChange} // Pass handleChange function as event handler
              color="secondary"
              dir="ltr"
            />
            <TextField
              label="رمز عبور" // Display label above the input field
              type="password" // Set type='password' for hiding characters as they are typed
              id="password" // Assign unique identifier for the password field
              name="password"
              size="small" // Adjust size if necessary
              onChange={handleChange} // Keep the same onChange handler
              color="secondary"
              dir="ltr"
            />
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={handleSubmit}>
              ثبت نام
            </Button>
            {err && <p>{err}</p>}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "max-content",
              }}
            ><Typography>
                {"  "}
                حساب کاربری دارید؟
              </Typography>
              <Link to="/login"><Typography>{"  "}ورود{"  "}</Typography></Link>
            </span>
          </Stack>
        </form>
      </Stack>
    </Paper>
  );
  return (
    <div>
      <Helmet>
        <title>تولی هاب: ثبت نام</title>
      </Helmet>
      <Stack
        component="main"
        direction="row"
        sx={{
          minHeight: "100vh",
        }}
      >
        {renderSection}
        {renderContent}
      </Stack>
    </div>
  );
};

export default Register;
